const SMALL_BUSINESSES = {
  label: 'Small Businesses',
  link: 'https://www.constantcontact.com/forward/action-plan-general'
}
const RESTAURANTS = {
  label: 'Restaurants',
  link: 'https://www.constantcontact.com/forward/action-plan-restaurant'
}
const NON_PROFIT = {
  label: 'Non Profit',
  link: 'https://www.constantcontact.com/forward/action-plan-nonprofit'
}
const REAL_ESTATE = {
  label: 'Real Estate',
  link: 'https://www.constantcontact.com/forward/action-plan-real-estate'
}
const RETAIL = {
  label: 'Retail',
  link: 'https://www.constantcontact.com/forward/action-plan-retail'
}
const HOME_AND_BUILDING = {
  label: 'Home and Building',
  link: 'https://www.constantcontact.com/forward/action-plan-home'
}
const TRAVEL_AND_TOURISM = {
  label: 'Travel and Tourism',
  link: 'https://www.constantcontact.com/forward/action-plan-travel'
}
const ARTS_AND_CULTURE = {
  label: 'Arts and Culture',
  link: 'https://www.constantcontact.com/forward/action-plan-arts'
}
const HEALTH_CLUBS_AND_FITNESS = {
  label: 'Health Clubs and Fitness',
  link: 'https://www.constantcontact.com/forward/action-plan-fitness'
}

export const ALL_ACTION_PLAN_ITEMS = [
  SMALL_BUSINESSES,
  RESTAURANTS,
  NON_PROFIT,
  REAL_ESTATE,
  RETAIL,
  HOME_AND_BUILDING,
  TRAVEL_AND_TOURISM,
  ARTS_AND_CULTURE,
  HEALTH_CLUBS_AND_FITNESS
]
