import React from 'react'
import Typography from '@eig-builder/core-ui/Typography'
import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import ActionPlans from '../action-plans'
import ToolPanel from '../tool-panel'
import { ALL_PANEL_ITEMS } from './panel-items.constants'

import { Hero, Resource, Tools } from './covid.styles.js'

export default ({ images }) => {
  return (
  <>
    <Hero>
      <Hero.Copy variant='h1'>
        <Typography variant='h1' gutterBottom>Small Business Resources during COVID-19 crisis</Typography>
        <Typography variant='body1'>In these challenging times, we're here to help.</Typography>
      </Hero.Copy>
    </Hero>
    <Resource>
      <Resource.container>
        <Typography variant='h2' gutterBottom>Resource</Typography>
        <div className='d-flex flex-nowrap'>
          <AccountBalanceIcon />
          <Resource.description href='https://www.sba.gov/disaster-assistance/coronavirus-covid-19' rel='noopener' target='_blank'>
            The U.S. Small Business Administration is offering designated states and territories low-interest federal disaster loans for working capital to small businesses suffering substantial economic injury as a result of the Cornoavirus (COVID-19)
          </Resource.description>
        </div>
      </Resource.container>
    </Resource>
    <ActionPlans />
    <Tools>
      <Tools.container>
      <Typography variant='h2' gutterBottom>Tools we offer</Typography>
        {ALL_PANEL_ITEMS.map((panel, i) => {
          return <ToolPanel key={panel.title} title={panel.title} description={panel.description} fluid={images[i].childImageSharp.fluid} />
        })}
        <Button onClick={() => window.location.href = process.env.GATSBY_SIGNUP_SITE_URL}
              dataElementLocation={DataElementLocations.BODY}
              dataElementLabel='body.covid-get-started'
              dataElementId='sitebuilder-covid-get-started'>
          Get Started
        </Button>
      </Tools.container>
    </Tools>
  </>
)
}