import React from 'react'
import Layout from './../components/layout'
import CovidComponent from './../components/covid'
import SEO from '../components/seo'
import { graphql } from 'gatsby'


const IndexPage = ({ data: {
  PanelImage1,
  PanelImage2,
  PanelImage3,
  PanelImage4
} }) => {
  const images = [
    PanelImage1,
    PanelImage2,
    PanelImage3,
    PanelImage4
  ]
  return (
    <Layout isWhiteHeader container>
      <SEO
          title='COVID-19 Relief'
          keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
          pageType={'product_page'}
          flowType={'none'} />
      <CovidComponent images={images} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    PanelImage1: file(relativePath: { eq: "customize.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage2: file(relativePath: { eq: "mail_onboarding.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage3: file(relativePath: { eq: "store.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    PanelImage4: file(relativePath: { eq: "blog.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`