import styled from 'styled-components'
import bannerBackground from '../../images/bannerBg.png'

const Hero = styled.div`
  width: 100%;
  height: 500px;
  background-color: #0050C7;
  background-image: url(${bannerBackground});
  position: relative;
`
Hero.Copy = styled.div`
  max-width: 600px;
  width: 90%;
  color: white;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(42px);
  h1 {
    font-size: 2.5em;
    line-height: 1.2em;
    @media(max-width: 400px) {
      font-size: 1.5em;
      line-height: 1em;
    }
  }
`
const Resource = styled.div`
  padding: 30px 20px;
  background-color: #E6FAF9;
  color: #2C4850;
`
Resource.container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`
Resource.description = styled.a`
  display: inline-block;
  margin: 0;
  margin-left: 20px;
  margin-bottom: 20px;
`
const Tools = styled.div`
  background-color: #E6FAF9;
  padding: 30px 20px;
  color: #2C4850;
`
Tools.container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

export { Hero, Resource, Tools }