import React from 'react'
import Panel from './tool-panel.styles.js'

export default ({ title, description, fluid}) => {
  return (
    <Panel>
      <Panel.imageHolder>
        <Panel.Image fluid={fluid} />
      </Panel.imageHolder>
      <Panel.textContainer>
        <Panel.title variant='h3' gutterBottom>
          {title}
        </Panel.title>
        <Panel.description variant='subtitle1'>
          {description}
        </Panel.description>
      </Panel.textContainer>
    </Panel>
  )
}