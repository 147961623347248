const WEBSITE = {
  title: 'Website',
  description: 'Answer a few questions about your business, blog, or portfolio and our A.I. builder will start building a site based on your answers. No coding required.'
}
const EMAIL_MARKETING = {
  title: 'Email Marketing',
  description: `We've created an email marketing solution that works seamlessly with your website, domain, and e-commerce store.`
}
const ONLINE_STORE = {
  title: 'Online Store',
  description: 'Quickly add products, prices, and all the information you need to make your product stand out online. Creating an e-commerce website has never been so simple.'
}
const BLOG = {
  title: 'Blog',
  description: 'Whether your blog is designed to help market your business or to create a place where you can share your ideas and build a community, our blog-creation features make it easy.'
}

export const ALL_PANEL_ITEMS = [
  WEBSITE,
  EMAIL_MARKETING,
  ONLINE_STORE,
  BLOG
]
