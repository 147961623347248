import styled, { css } from 'styled-components'
import DescriptionIcon from '@material-ui/icons/Description'
import Typography from '@eig-builder/core-ui/Typography'

const ActionPlans = styled.div`
  padding: 20px;
  color: #2C4850;
`
ActionPlans.container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

ActionPlans.sectionHeader = styled.div`
  padding: ${ ({ theme }) => theme.spacing(6) }px 0;
`

ActionPlans.title = styled(Typography)``

ActionPlans.description = styled(Typography)``

ActionPlans.sectionBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  @media(max-width: 450px){
    grid-template-columns: repeat(1, 1fr);
  }
`

ActionPlans.item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
`

ActionPlans.Icon = styled(DescriptionIcon)`
  margin-right: ${ ({ theme }) => theme.spacing(8) }px
`

ActionPlans.label = styled.a`
  color: ${ ({ theme }) => theme.palette.primary.main };
  ${ ({ theme }) => css`${ theme.typography.body1 }` }
`

export default ActionPlans
