import styled from 'styled-components'
import Typography from '@eig-builder/core-ui/Typography'
import Img from 'gatsby-image'

const Panel = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.10);
  @media(max-width: 600px){
    height: auto;
    flex-wrap: wrap;
  }
`
Panel.imageHolder = styled.div`
  margin-left: 20px;
  @media(max-width: 600px){
    margin: 0;
    padding: 20px;
    padding-bottom: 0;
    height: auto;
    width: 100%;
  }
`
Panel.Image = styled(Img)`
  width: 200px;
  @media(max-width: 600px){
    width: auto;
  }
`

Panel.textContainer = styled.div`
  padding: 20px;
`
Panel.title = styled(Typography)`
  color: 2C4850;
`
Panel.description = styled(Typography)``

export default Panel