import React from 'react'

import Plans from './action-plan.styles'
import { ALL_ACTION_PLAN_ITEMS } from './action-plan-items.constants'

const ActionPlans = () => (
  <Plans>
    <Plans.container>
      <Plans.sectionHeader>
        <Plans.title variant='h2' gutterBottom>
          Action plans
        </Plans.title>
        <Plans.description variant='subtitle1'>
          from our partner company Constant Contact
        </Plans.description>
      </Plans.sectionHeader>
      <Plans.sectionBody>
        {ALL_ACTION_PLAN_ITEMS.map(({ label, link }) => (
          <Plans.item key={label}>
            <Plans.label href={link} target='_blank' rel='noopener'>
              <Plans.Icon color='primary' />
            </Plans.label>
            <Plans.label href={link} target='_blank' rel='noopener'>
              {label}
            </Plans.label>
          </Plans.item>
        ))}
      </Plans.sectionBody>
    </Plans.container>
  </Plans>
)

export default ActionPlans
